<template>
  <div>
    <data-table
      title="Affiliate"
      url-name="affiliate"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :loading.sync="loading"
      :items-length="totalItems"
      :page="page"
      :pageCount="pageCount"
      :items-per-page="itemsPerPage"
      :timezone="getTz()"
      @update="fetchUser"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DataTable from '@/components/data-table/DataTable.vue'

import axios from 'axios'
import _ from 'lodash'

export default {
  components: {
    DataTable,
  },
  data: () => ({
    selected: [],
    options: {},
    loading: false,
    totalItems: 0,
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    headers: [
      { text: 'Actions', value: 'actions', align: 'center', sortable: false, width: 50 },
      // {
      //   text: 'ID',
      //   align: 'start',
      //   sortable: false,
      //   value: 'id',
      //   width: 70,
      // },
      { text: 'Name', value: 'name', width: 150, },
      { text: 'Email', value: 'email', },
      { text: 'Phone Number', value: 'phone', width: 150 },
      { text: 'Title', value: 'title', width: 150, },
      { text: 'Rep ID', value: 'rep_id', width: 150, },
      // { text: 'Badge', value: 'badge', width: 90, },
      { text: '# Accounts', value: 'num_accounts', width: 150, sortable: false, },
      { text: 'Email Verified', value: 'email_verified_at', align: 'center', width: 130, },
      { text: 'Last Login', value: 'last_login', width: 190 },
      { text: 'Created At', value: 'created_at', width: 190 },
    ],
    items: [],
  }),

  computed: {
    ...mapGetters([ 'getTz' ]),
  },

  mounted () {
    document.title = 'Affiliates';

    this.fetchUser()
  },

  methods: {
    async fetchUser( search = '' ) {
      try {
        if (_.isEmpty(this.options)) {
          return
        }

        this.loading = true
        if (this.$root.progressbar) {
          this.$root.progressbar.show()
        }
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { itemsPerPage, page, sortBy, sortDesc} = this.options

        if (!sortBy[0]) {
          sortBy[0] = 'created_at'
          sortDesc[0] = true
        }

        const params = {
          kw: search,
          limit: itemsPerPage,
          page: page,
          order_by: sortBy[0] ? sortBy[0] : 'created_at',
          order_direction: sortDesc[0] ? 'desc' : 'asc',
        }

        const { data: {data, meta} } = await axios.get( `${url}/api/pb-reps`, {
          headers: { Authorization: `Bearer ${token}` },
          params: params
        })

        this.page = meta.current_page
        this.pageCount = meta.last_page
        this.totalItems = meta.total
        this.itemsPerPage = meta.per_page

        this.items = data.map( u => {
          return {
            id: u.id,
            name: u.name,
            email: u.email,
            phone: u.phone,
            title: u.title,
            rep_id: u.rep_id,
            // district: u.district,
            num_accounts: u.accounts?.toLocaleString('en-US'),
            email_verified_at: u.email_verified_at,
            last_login: u.last_login,
            created_at: u.created_at,
          }
        })
      } catch (error) {
        console.error('error', error)
      } finally {
        this.loading = false
        if (this.$root.progressbar) {
          this.$root.progressbar.hide()
        }
      }
    },
  },
}
</script>
